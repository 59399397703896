import { ErrorBoundary as Component } from 'react-error-boundary';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function ErrorBoundary({ stack = false, ...props }) {
  return (
    <Component
      FallbackComponent={({ error }, { componentStack }) => (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <pre>{error.message}{stack ? componentStack : ''}</pre>
        </Alert>
      )}
      {...props}
    />
  );
}
