import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider, createTheme } from '@material-ui/core';
import { amber } from '@material-ui/core/colors';

import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';

const App = lazy(() => import('./App'));
const Login = lazy(() => import('./views/Login'));

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: amber,
  },
});

ReactDOM.render(
  (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary stack>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="*">
                <App />
              </Route>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </MuiThemeProvider>
  ),
  document.getElementById('root'),
);
