import { CircularProgress, CircularProgressProps, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
}));

export default function Loader(props: CircularProgressProps) {
  const classes = useStyles();

  return (
    <CircularProgress className={classes.root} {...props} />
  );
}
